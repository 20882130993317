import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import UserInputText from "../common/UserInputText";
import UserSelect from "../common/UserSelect";
import { userProfileUpdateSchema } from "../../validations/ValidationSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  generalInfoApi,
  getUserDataApi,
  updateProfileApi,
  verifyUserEmailAccount,
} from "../../services/userService";
import { toast } from "react-toastify";
import { setUser } from "../../redux/userProfileSlics";
import Loader from "../common/Loader";
import { useTranslation } from "react-i18next";
import InputPhoneCode from "../common/InputPhoneCode";
import PhoneVerifyModal from "./PhoneVerifyModal";
import Cookies from "universal-cookie";
import { GOVERNORATE_LIST_API } from "../../constants/paths";
import { homeGovernorateList } from "../../services/homePageServices";
import { useNavigate } from "react-router-dom";

function UpdateProfileForm() {

  const { t } = useTranslation()

  const { i18n } = useTranslation()

  const cookies = new Cookies()

  const navigate = useNavigate()


  // USER DATA FROM REDUX
  const userData = useSelector((state) => state?.user?.userData);

  const [loading, setLoading] = useState(false);

  const [openPhoneVerifyModal, setOpenPhoneVerifyModal] = useState(false)
  const dispatch = useDispatch();

  // STATES FOR DROPDOWN OPTIONS
  const [timeLineOptions, setTimeLineOptions] = useState([]);
  const [platformUsageOptions, setPlatformUsageOptions] = useState([]);
  const [areaOfInterest, setAreaOfInterest] = useState([])

  const [verificationMailSent, setVerificationMailSent] = useState(false)

  useEffect(() => {
    // GET SELECT DROPDOWN DATA 
    generalInfoApi()
      .then((response) => {
        if (response?.buyRentTimelineMap && response?.buyRentTimelineMap !== null) {
          const timeLine = Object.entries(
            response?.buyRentTimelineMap
          ).map(([value, label]) => ({ value, label }));
          setTimeLineOptions(timeLine);
        }

        if (response?.platformUsageMap && response?.platformUsageMap !== null) {
          const platformUsage = Object.entries(
            response?.platformUsageMap
          ).map(([value, label]) => ({ value, label }));
          setPlatformUsageOptions(platformUsage);
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });

    const fetchGovernorateList = () => {
      homeGovernorateList().then(response => {
        const interestList = response?.map((governorate) => ({
          label: governorate?.capital, value: governorate?.id
        }))
        setAreaOfInterest(interestList)
      }).catch((error) => {
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      })
    }
    fetchGovernorateList()
  }, []);

  // FORM SUBMIT
  const handleSubmit = (values) => {

    setLoading(true);

    let formData = {
      login: userData?.email,
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: userData?.email,
      langKey: values?.langKey,

    };
    if (values?.address && values?.address !== "") {
      formData.address = values?.address
    }
    if (values?.platformUsage && values?.platformUsage?.length > 0) {
      formData.platformUsages = values?.platformUsage ?? []
    }
    if (values?.isFirstBuyOrRent && values?.isFirstBuyOrRent !== "") {
      formData.thisFirstBuyOrRent = values?.isFirstBuyOrRent
    }
    if (values?.timeLine && values?.timeLine !== "") {
      formData.buyRentTimeline = values?.timeLine
    }
    if (values?.areaOfInterest && values?.areaOfInterest !== "") {
      formData.areaOfInterests = values?.areaOfInterest
    }

    // UPDATE PROFILE API CALLING
    updateProfileApi(formData)
      .then((response) => {
        toast?.success(response?.message ?? t("PROFILE UPDATED!"))
        setLoading(false);

        // REDUX STORE UPDATE WITH UPDATED PROFILE DATA
        getUserDetails()
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };

  // HANDLE EMAIL VERIFY

  const handleEmailVerify = () => {
    // toast.info("UNDER DEVELOPMENT")
    verifyUserEmailAccount().then((response) => {
      setVerificationMailSent(true)
      getUserDetails()
      toast.success(response?.message)
    }).catch(error => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  const getUserDetails = () => {
    getUserDataApi()
      .then((userDataResponse) => {
        setLoading(false);
        dispatch(setUser({ userData: userDataResponse }));
        // UPDATE USER LANGUAGE IN COOKIES
        // cookies.remove("userLangKey")
        // cookies.set("userLangKey", userDataResponse?.langKey)
        // i18n.changeLanguage(userDataResponse?.langKey)
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  return (
    <div className="col-lg-12">
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Formik
          initialValues={{
            firstName: userData?.firstName ?? "",
            lastName: userData?.lastName ?? "",
            email: userData?.email ?? "",
            countryCode: userData?.countryCode ?? "",
            phoneNumber: userData?.mobileNumber,
            langKey: userData?.langKey ?? "",
            address: userData?.address ?? "",
            platformUsage: userData?.platformUsages ?? [],
            areaOfInterest: userData?.areaOfInterests ?? [],
            isFirstBuyOrRent: userData?.thisFirstBuyOrRent ?? false,
            timeLine: userData?.buyRentTimeline ?? "",
          }}
          enableReinitialize={true}
          validationSchema={userProfileUpdateSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            touched,
            isValid,
            errors,
          }) => (
            <Form className="form-style1">
              <div className="row align-items-end">
                <div className="col-sm-6 col-xl">
                  <div className="mb20">
                    <UserInputText
                      id="firstName"
                      name="firstName"
                      type="text"
                      label={t("FIRST NAME")}
                      placeholder={t("FIRST NAME")}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values?.firstName}
                      className={`form-control ${touched?.firstName && errors?.firstName ? "error" : ""
                        }`}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-xl">
                  <div className="mb20">
                    <UserInputText
                      id="lastName"
                      name="lastName"
                      type="text"
                      label={t("LAST NAME")}
                      placeholder={t("LAST NAME")}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values?.lastName}
                      className={`form-control ${touched?.lastName && errors?.lastName ? "error" : ""
                        }`}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-xl">
                  <UserInputText
                    id="email"
                    name="email"
                    type="email"
                    label={t("EMAIL")}
                    readOnly={true}
                    placeholder={t("EMAIL")}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values?.email}
                    className={`form-control ${touched?.email && errors?.email ? "error" : ""
                      }`}
                  />
                </div>
                {
                  userData?.emailVerified === false && verificationMailSent === false ? <div className="col-sm-6 col-xl-auto">
                    <div className="text-end">
                      <button type="button" onClick={handleEmailVerify} className="ud-btn btn-thm mb20">{t("VERIFY EMAIL")}</button>
                    </div>
                  </div> : ""
                }
              </div>
              <div className="row ">
                <div className="col-sm-12 col-xl-8">
                  <div className="row align-items-end">
                    <div className="col-md-4 col-xl-3">
                      <InputPhoneCode
                        id="countryCode"
                        name="countryCode"
                        type="number"
                        country="jo"
                        label={t("CODE")}
                        placeholder={t("CODE")}
                        handleChange={(code) => { setFieldValue("countryCode", code) }}
                        disabled={true}
                        handleBlur={handleBlur}
                        value={values?.countryCode}
                        className={`${touched?.countryCode && errors?.countryCode ? "error" : ""
                          }`}
                      />
                    </div>
                    <div className="col-md-8 col-xl-6">
                      <UserInputText
                        id="phoneNumber"
                        label={t("PHONE NUMBER")}
                        placeholder={t("YOUR PHONE NUMBER")}
                        value={values?.phoneNumber}
                        name="phoneNumber"
                        handleChange={handleChange}
                        disabled={true}
                        handleBlur={handleBlur}
                        className={`form-control ${touched?.phoneNumber && errors?.phoneNumber ? "error" : ""
                          }`} />
                    </div>
                    <div className="col-md col-xl-3">
                      <button type="button" className="ud-btn btn-thm mb20" onClick={() => {
                        // toast.info("UNDER DEVELOPMENT")
                        setOpenPhoneVerifyModal(true)
                      }}>{userData?.mobileNumberVerified ? t('EDIT') :
                        t("ADD")} & {t("VERIFY")}</button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-xl-4">
                  <UserSelect
                    id="langKey"
                    name="langKey"
                    label={t("PREFERRED LANGUAGE")}
                    placeholder={t("PREFERRED LANGUAGE")}
                    handleChange={(value) => {
                      setFieldValue("langKey", value);
                    }}
                    handleBlur={handleBlur}
                    value={values?.langKey}
                    options={[
                      {
                        value: "en",
                        label: "English",
                      },
                      {
                        value: "ar",
                        label: "Arabic",
                      },
                    ]}
                    className={`form-control ${touched?.langKey && errors?.langKey
                      }`}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <UserInputText
                    id="address"
                    name="address"
                    type="text"
                    label={t('ADDRESS')}
                    placeholder="Your Address"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values?.address}
                    className={`form-control ${touched?.address && errors?.address ? "error" : ""
                      }`}
                  />

                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xl-4">
                  <UserSelect
                    id="platformUsage"
                    name="platformUsage"
                    label={t('MAIN USAGE OF THE PLATFORM')}
                    placeholder={t('MAIN USAGE OF THE PLATFORM')}
                    handleChange={(value) => {
                      setFieldValue("platformUsage", value);
                    }}
                    handleBlur={handleBlur}
                    value={values?.platformUsage}
                    mode={"multiple"}
                    options={platformUsageOptions ?? []}
                    className={`form-control ${touched?.platformUsage && errors?.platformUsage
                      }`}
                  />
                </div>
                <div className="col-sm-6 col-xl-4">
                  <UserSelect
                    id="areaOfInterest"
                    name="areaOfInterest"
                    label={t('AREAS OF INTEREST')}
                    placeholder={t("AREAS OF INTEREST")}
                    handleChange={(value) =>
                      setFieldValue("areaOfInterest", value)
                    }
                    handleBlur={handleBlur}
                    value={values?.areaOfInterest}
                    mode={"multiple"}
                    options={areaOfInterest ?? []}
                    className={`form-control ${touched?.areaOfInterest && errors?.areaOfInterest
                      }`}
                  />
                </div>
                <div className="col-sm-6 col-xl-4">
                  <UserSelect
                    id="isFirstBuyOrRent"
                    name="isFirstBuyOrRent"
                    label={t('IS THIS YOUR FIRST BUY OR RENT?')}
                    placeholder={t('NOTHING SELECTED')}
                    handleChange={(value) => {
                      setFieldValue("isFirstBuyOrRent", value);
                    }}
                    handleBlur={handleBlur}
                    value={values?.isFirstBuyOrRent}
                    options={[
                      {
                        value: true,
                        label: t("YES"),
                      },
                      {
                        value: false,
                        label: t("NO"),
                      },
                    ]}
                    className={`form-control ${touched?.isFirstBuyOrRent && errors?.isFirstBuyOrRent
                      }`}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xl-4">
                  <UserSelect
                    id="timeLine"
                    name="timeLine"
                    label={t("WHEN IS THE TIMELINE TO BUY OR RENT?")}
                    placeholder="Nothing Selected"
                    handleChange={(value) => {
                      setFieldValue("timeLine", value);
                    }}
                    handleBlur={handleBlur}
                    value={values?.timeLine}
                    options={timeLineOptions}
                    className={`form-control ${touched?.timeLine && errors?.timeLine
                      }`}
                  />
                </div>
                <div className="col-md-12">
                  <div className="text-end">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="ud-btn btn-dark"
                    >
                      {t('UPDATE PROFILE')}
                      <i className="fal fa-arrow-right-long" />
                    </button>

                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <PhoneVerifyModal openModal={openPhoneVerifyModal} setOpenModal={setOpenPhoneVerifyModal} />
    </div>
  );
}

export default UpdateProfileForm;
