import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { nameBasedRoutes } from '../../router/public';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Popconfirm } from 'antd';
import LoginModal from '../common/LoginModal';
import { propertyLikeStatusApi } from '../../services/propertyService';
import { toast } from 'react-toastify';
import { MdAdd } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { addNewProperty, removeProperty } from '../../redux/comparePropertySlice';
import { COMPARE_ERROR } from '../../validations/ValidationErrors';
import ReactGA4 from "react-ga4"
import { GoVerified } from "react-icons/go";
// COMMON PROPERTY CARD COMPONENT FOR COMPLETE WEBSITE
const PropertyCard = ({ propertyDetails, isFavorite, setPropertiesList, isCompare }) => {
	const { t } = useTranslation()


	// TO CHECK WEATHER THE USER IS LOGGED IN OR NOT
	const userToken = useSelector((state) => state?.user?.userData)

	// PROPERTIES AVAILABLE FOR COMPARE
	const compareProps = useSelector((state) => state?.compareProps)

	const dispatch = useDispatch()

	const offeringType = useSelector((state) => state?.generalInfo?.platformUsageMap)

	// SHOW LOGIN MODAL OR NOT
	const [showLogin, setShowLogin] = useState(false);

	const [loading, setLoading] = useState(false)

	// FAVORITE MARK STATE
	const [isLiked, setIsLiked] = useState(propertyDetails?.isLiked ?? false)

	// CHECK IF ADDED TO COMPARE OR NOT
	const [isAddedToCompare, setIsAddedToCompare] = useState(compareProps?.includes(propertyDetails?.id) ? true : false)

	// HANDLE CLICK ON FAVORITE BUTTON
	const handleLikeClick = (isLike) => {
		setLoading(true)
		propertyLikeStatusApi(propertyDetails?.id, isLike).then((response) => {

			// GOOGLE ANALYTICS EVENT ON FAVORITE MARK
			ReactGA4.event({
				category: "update_fav",
				action: "click",
				id: propertyDetails?.id,
				item: "Update Fav Status",
				description: isLike ? `property ${propertyDetails?.id} is marked as favorite` : `property ${propertyDetails?.id} is removed from favorite,`
			});

			toast.success(response?.message)

			// IF FAVORITE PAGE THEN REMOVE PROPERTY CARD FROM LIST
			if (isFavorite) {
				setPropertiesList(prev => (
					prev.filter(element => element?.id !== propertyDetails?.id)
				));
			} else {
				setIsLiked(!isLiked)
			}
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}

	// ADD TO COMPARE
	const handleAddCompare = (id) => {
		// NOT ALLOWED TO COMPARE MORE THAN 4 PROPERTIES
		if (compareProps?.length <= 3) {
			setIsAddedToCompare(true)
			// ADD NEW PROPERTY TO COMPARE IN REDUX
			dispatch(addNewProperty({ newProp: id }))
		} else {
			toast.error(COMPARE_ERROR)
		}

	}
	// REMOVE FROM COMPARE	
	const handleRemoveCompare = (id) => {
		setIsAddedToCompare(false)
		// REMOVE PROPERTY FROM COMPARE IN REDUX
		dispatch(removeProperty({ propId: id }))
	}

	useEffect(() => {
		setIsAddedToCompare(compareProps?.includes(propertyDetails?.id) ? true : false)
	}, [compareProps, propertyDetails])


	return (
		<div className="listing-style1 style3">
			<div className="list-thumb property-thumb">
				<img
					src={propertyDetails?.propertyImage?.
						thumbnailImageName}
					className="w-100"
					alt="Properties For You"
				/>
				{
					propertyDetails?.isFeatured &&
					<div className="list-tag fz12">
						<span className="flaticon-electricity me-2" />
						{t("FEATURED")}
					</div>
				}
				{
					(propertyDetails?.verified && propertyDetails?.verified === true) && <div className="fz12 verified-tag">
					<GoVerified className='fs-18 mb-1 me-1'/>
					{t('VERIFIED')}</div>
				}
				{
					(propertyDetails?.virtualTourUrl && propertyDetails?.virtualTourUrl !== '') && <div className="list-tag fz12 tour3d-tag"><i className="fal fa-cube me-2"></i> {t('3D TOUR')}</div>
				}
				<div className="list-price">
					{t("JOD")} {propertyDetails?.priceInJod ?? ''}  {propertyDetails?.propertyOfferingType === 'RENT' && <span>/ yr</span>}
				</div>
			</div>
			<div className="list-content">
				<h6 className="list-title">
					<Link to={`${nameBasedRoutes?.propertyDetails?.path}/${propertyDetails?.id}/${propertyDetails?.slug}`} className='line-clamp'>
						{propertyDetails?.title ?? ''}
					</Link>
				</h6>
				<p className="list-text line-clamp">
					{propertyDetails?.village?.village && propertyDetails?.village?.village + ', '}
					{propertyDetails?.directorate?.directorate && propertyDetails?.directorate?.directorate + ', '}
					{propertyDetails?.governorate?.governorate && propertyDetails?.governorate?.governorate + ', '}
					Jo
				</p>
				<div className="list-meta d-flex align-items-center">
					{
						propertyDetails?.propertyDetail?.noOfBedRooms &&
						<span className="mr25">
							<span className="flaticon-bed" /> {propertyDetails?.propertyDetail?.noOfBedRooms} {t("BED")}
						</span>
					}
					{
						propertyDetails?.propertyDetail?.noOfBathRooms && <span className="mr25">
							<span className="flaticon-shower" /> {propertyDetails?.propertyDetail?.noOfBathRooms} {t("BATH")}
						</span>
					}
					{
						propertyDetails?.propertyDetail?.areaSqm && <span className="mr25">
							<span className="flaticon-expand" />{propertyDetails?.propertyDetail?.areaSqm} {t("SQ.M.")}
						</span>
					}
				</div>
				<hr className="mt-2 mb-2" />
				<div className="list-meta2 d-flex justify-content-between align-items-center">
					{
						propertyDetails?.propertyOfferingType === "BUY" ?
							<span className="for-what text-orange fz15 text-capitalize">
								<i className="fas fa-circle fz10 pe-2 ms-1" />
								{t("FOR")} {propertyDetails?.propertyOfferingType ?

									offeringType[propertyDetails?.propertyOfferingType] : ""}
							</span> : <span className="for-what text-blue fz15 text-capitalize">
								<i className="fas fa-circle fz10 pe-2 ms-1" />
								{t("FOR")} {propertyDetails?.propertyOfferingType ? offeringType[propertyDetails?.propertyOfferingType] : ""}
							</span>
					}
					<div className="icons d-flex align-items-center">
						<Link
							to={`${nameBasedRoutes?.propertyDetails?.path}/${propertyDetails?.id}`}
							target='_blank'
						>
							<span className="flaticon-fullscreen" />
						</Link>
						{
							userToken ? <button
								disabled={loading}
								className="btn icon mr10 bg-none" onClick={() => handleLikeClick(!isLiked)}>
								{isLiked !== true ?
									<span className="fal fa-heart" /> : <span className="fa-solid fa-heart text-danger" />
								}
							</button> :
								<Popconfirm
									title={t("NOT LOGGED IN")}
									description={t("PLEASE LOG IN TO MARK THIS AS A FAVORITE")}
									onConfirm={() => { setShowLogin(!showLogin) }}
									okText={t("LOGIN NOW")}
									okButtonProps={{ className: "bgc-thm" }}
									cancelText={t("CANCEL")}
								>
									<button className="btn icon mr10 bg-none">
										<span className="fal fa-heart" />
									</button>
								</Popconfirm>
						}
					</div>
				</div>
				<div className="d-flex justify-content-between">
					<span className="pro-card-agency-name">
						<Link className="ud-btn2" to={`${nameBasedRoutes?.agencyDetails?.path + "/"+ propertyDetails?.agencyId}${propertyDetails?.agency?.slug ? '/'+ propertyDetails?.agency?.slug : ''}`}>
							{propertyDetails?.agency?.name}
							<i className="fz14 fal fa-arrow-right-long" />
						</Link>
					</span>
					{
						isCompare ? <span>
							{
								isAddedToCompare === false ?
									<button className='ud-btn btn btn-thm2 p-2' onClick={() => handleAddCompare(propertyDetails?.id)}> <MdAdd className='mb-1' />  {t("COMPARE")}</button> :
									<button className='ud-btn btn btn-thm2 p-2' onClick={() => handleRemoveCompare(propertyDetails?.id)}> <RxCross2 className='mb-1' />  {t("ADDED")}</button>}

						</span> : ""
					}
				</div>
			</div>
			{/* OPEN LOGIN MODAL IF USER IS NOT LOGGED IN */}
			<LoginModal
				show={showLogin}
				close={() => setShowLogin(false)}
			/>
		</div>
	)
}

export default PropertyCard
