import AgencyListImg from "../../assets/images/team/agency-2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAgencyListingData } from "../../services/agencyService";
import { toast } from "react-toastify";
import { Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Empty, Skeleton } from "antd";
import { useDispatch } from "react-redux";
import { setHeaderClass } from "../../redux/layoutSlice";
import { nameBasedRoutes } from "../../router/public";
import SeoComponent from "../../components/seo/SeoComponent";

export const Agency = () => {

  const { t } = useTranslation()

  const location = useLocation()

  const params = new URLSearchParams(location.search)

  const currentPage = params.get("page") ?? 1

  const [loading, setLoading] = useState(true)
  const [pageSize] = useState(9)
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [agentListingData, setAgentListingData] = useState([])
  const pageOffset = (currentPage - 1) * pageSize + 1

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const handlePageChange = (event, value) => {

    navigate(`${nameBasedRoutes?.agency?.path}?page=${value}`)
  }

  // get Agency Listing Data
  const fetchListingData = () => {
    setLoading(true)
    let params = {
      size: pageSize
    }

    if (currentPage) {
      params.page = currentPage - 1
    }
    getAgencyListingData(params).then(response => {
      setAgentListingData(response?.data)
      setTotalRowCount(response?.headers["x-total-count"])
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(error?.response?.data?.error_description
        ? error?.response?.data?.error_description
        : error?.message)
    })
  }
  useEffect(() => {
    fetchListingData()
  }, [currentPage])


  useEffect(() => {
    dispatch(setHeaderClass({ className: "container" }))
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, [location])


  return (
    <div className="body_content">

      {/* SEO IMPLEMENTATION */}
      <SeoComponent
        title={t("AGENCIES_PAGE_TITLE")}
        meta_title={t("AGENCIES_META_TITLE")}
        description={t("AGENCIES_META_DESCRIPTION")}
        keywords={t("AGENCIES_KEYWORDS")}
        canonical={process.env.REACT_APP_WEBSITE_WEB_URL + nameBasedRoutes?.agency?.path}
      />
      {/* <LoaderMu loading={loading} /> */}
      {/* UI Elements Sections */}
      <section className="breadcumb-section bgc-white pt30 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">  {t("AGENCY")}</h2>
                <div className="breadcumb-list">
                  <Link to="/">  {t("HOME")}</Link>
                  <Link to="/agencies">  {t("AGENCY")}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Agent Section Area */}
      <section className="our-agents bgc-white pt-0">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            {

              loading === true ?
                [...Array(6)].map((element, index) => {
                  return <div key={index + 1} className="col-sm-6 col-xl-4 my-2">
                    <Skeleton.Button active={true} size={"100%"} shape={"square"} block={true} style={{ height: "400px" }} />
                  </div>
                })

                :
                
                agentListingData?.length > 0 ? agentListingData.map((item, index) => {
                  //  return <div>
                  return <div key={index + 1} className="col-md-6 col-lg-4">
                    <div className="agency-style1 p30 bdrs12 bdr1 mb30">
                      <div className="agency-img text-center">
                        <img src={item.logo != null ? item.logo : AgencyListImg} alt="Agency logo" className="w-100 mxh-250" />
                        {/* <img src={AgencyListImg} alt="Agency logo" /> */}
                        <div className="tag">{item?.propertyCount > 0 ? item?.propertyCount : ''}</div>
                      </div>
                      <div className="agency-details pt40">
                        <h6 className="agency-title mb-1">{item.name}</h6>
                        <p className="fz15">{item.address}</p>
                        <div className="d-grid">
                          <Link
                            to={`/agency-detail/${item?.id}${item?.slug ? "/" + item?.slug  : ''}`}
                            className="ud-btn btn-white2"
                          >
                            {t('VIEW DETAILS')}
                            <i className="fal fa-arrow-right-long" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                }) : <Empty description={<span>No Agency available</span>} />
            }
          </div>
          {/* Pagination section */}
          {
            agentListingData?.length > 0 ? <div
              className="row justify-content-center wow fadeInUp"
              data-wow-delay="500ms"
            >
              <div className="mbp_pagination text-center mt30 dataTable_pagination">
                <Pagination
                  count={Math.ceil(totalRowCount / pageSize)}
                  siblingCount={1} page={parseInt(currentPage)}
                  className="d-inline-block" size="large"
                  onChange={(event, value) => handlePageChange(event, value)}
                />
                {/* {
                  totalRowCount < pageSize ? <p className="mt10 pagination_page_count text-center">
                    {" " + pageOffset}–{totalRowCount} – {currentPage * pageSize} {t("OF")} {totalRowCount}+ {t('AGENCY AVAILABLE')}
                  </p> : <p className="mt10 pagination_page_count text-center">
                    {" " + pageOffset} – {currentPage * pageSize} {t("OF")} {totalRowCount} {t('AGENCY AVAILABLE')}
                  </p>
                } */}
              </div>
            </div> : ""
          }
        </div>
      </section>
    </div>
  );
};