import React from 'react'
import MapMarker from './MapMarker'
import { useTranslation } from 'react-i18next'

function PopertyAddress({ propertyData }) {
  const { t } = useTranslation()
  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
      <h4 className="title fz17 mb30 mt0">
        <i className="fal fa-map-marker-alt me-2" />
        {t('ADDRESS')}
      </h4>
      <div className="row ">
        <div className="col-md-6 col-xl-6">
          <div className="d-flex justify-content-between">
            <div className="pd-list">
              <div className="row">
                <div className="col-sm-4">
                  <p className="fw600 mb10 ff-heading dark-color">
                    {t('ADDRESS')}
                  </p>
                </div>
                <div className="col text-end text-md-start">
                  <p className="text mb10">{propertyData?.googleGeocodeData?.formattedAddress ?? 'N/A'}</p>
                </div>
              </div>
              <div className='row'>
                <div className="col-sm-4">
                  <p className="fw600 mb10 ff-heading dark-color">
                    {t('DIRECTORATE')}
                  </p>
                </div>
                <div className="col text-end text-md-start">
                  <p className="text mb10">{propertyData?.directorate?.directorate ?? 'N/A'}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="fw600 mb10 ff-heading dark-color">
                    {t("COUNTRY")}
                  </p>
                </div>
                <div className="col text-end text-md-start">
                  <p className="text mb-0">{t("JORDAN")}</p>
                </div>
              </div>
            </div>
            {/* <div className="pd-list offset-xl-1 text-end text-md-start">
              <p className="text mb10">{propertyData?.googleGeocodeData?.formattedAddress + "fdsfsd fsdfj fsdjfl s dsfdslfj dfd" ?? 'N/A'}</p>
              <p className="text mb10">{propertyData?.directorate?.directorate ?? 'N/A'}</p>
              <p className="text mb-0">{t("JORDAN")}</p>
            </div> */}
          </div>
        </div>
        <div className="col-md-6 col-xl-5 offset-xl-1">
          <div className="pd-list">
            <div className="row">
              <div className="col-4">
                <p className="fw600 mb10 ff-heading dark-color">
                  {t('GOVERNORATE')}
                </p>
              </div>
              <div className="col text-end text-md-start">
                <p className="text mb10">{propertyData?.governorate?.governorate ?? 'N/A'}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p className="fw600 mb10 ff-heading dark-color">
                  {t('VILLAGE')}
                </p>
              </div>
              <div className="col text-end text-md-start">
                <p className="text mb10">{propertyData?.village?.village ?? 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="position-relative bdrs12 mt30">
            <MapMarker hideLocation={propertyData?.hideExactLocation} propertyTypeInfo={propertyData?.propertyType} coordinateData={propertyData?.coordinateWgs84} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopertyAddress
