import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_8_CHAR,
  PASSWORD_REGEX,
  CONFIRM_PASSWORD_REQUIRED,
  CURRENT_PASSWORD_REQUIRED,
  NEW_PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_MATCH,
  EMAIL_REQUIRED,
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  PHONE_NUMBER_VALIDATION,
  MINIMUM_7_DIGIT,
  MAXIMUM_15_DIGIT,
  VALID_EMAIL,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  LANG_KEY_REQUIRED,
  I_AGREE_REQUIRED,
  TEXT_MESSAGE_REQUIRED,
  NO_NEGATIVE_VALUE,
  TIME_REQUIRED,
  COUNTRY_CODE_REQUIRED,
  PHONE_NO_REQUIRED,
  COMPLAINT_REASON_REQUIRED,
  OTP_REQUIRED,
  MAXIMUM_10_DIGIT,
  INVALID_COUNTRY_CODE,
} from "./ValidationErrors";

// const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$|^www\.[^\s/$.?#].[^\s]*$/;

// const paswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d.*\d)(?=.*[!$%^()_+<>#*@`~&=\|'";:/?.,])\S+$/;
const paswordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!$}{%^()_+<>#*@`~&=\|'";:/?.,-])[A-Za-z\d!$}{%^()_+<>#*@`~&=\|'";:/?.,-]{8,14}$/;


// Profile
export const ProfileSchema = yup.object().shape({
  current_password: yup
    .string()
    .required(CURRENT_PASSWORD_REQUIRED)
    .matches(paswordRegex, PASSWORD_REGEX),
  new_password: yup
    .string()
    .required(NEW_PASSWORD_REQUIRED)
    .matches(paswordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("new_password"), ""], CONFIRM_PASSWORD_MATCH),
});

// Add CMS
export const AddCmsSchema = yup.object().shape({
  title: yup.string().required(EMAIL_REQUIRED),
  page_type: yup.string().required(EMAIL_REQUIRED),
  content: yup
    .string()
    .required(EMAIL_REQUIRED)
    .when("page_type", {
      is: "TEXT_WITH_ASSESSMENT_RESULT_APP",
      then: yup
        .string()
        .required(EMAIL_REQUIRED)
        .max(150, "Result app page type must be 150 characters or less."),
    })
    .when("page_type", {
      is: "CONTINUE_ASSESSMENT_APP",
      then: yup
        .string()
        .required(EMAIL_REQUIRED)
        .max(105, "Assessment page type must be 105 characters or less"),
    })
    .when("page_type", {
      is: "START_ASSESSMENT",
      then: yup
        .string()
        .required(EMAIL_REQUIRED)
        .max(150, "Assessment start must be 150 characters or less."),
      otherwise: yup.string().required(EMAIL_REQUIRED),
    }),
});

// Singup
export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email(VALID_EMAIL)
    .required(EMAIL_REQUIRED)
    .max(300, "Can not be more than 300 characters."),
  password: yup
    .string()
    .required('Password is required')
    .min(8, MINIMUM_8_CHAR)
    .max(14, MAXIMUM_30_CHAR),
});

// Singup
export const Singup = yup.object().shape({
  i_agree: yup.boolean().oneOf([true], I_AGREE_REQUIRED).required(I_AGREE_REQUIRED),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email(VALID_EMAIL)
    .required(EMAIL_REQUIRED),
  password: yup
    .string()
    .required('Password is required.')
    .matches(paswordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
});

// Singup
export const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().required(EMAIL_REQUIRED).email(VALID_EMAIL),
});

// Reset Password
export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required(NEW_PASSWORD_REQUIRED)
    .matches(paswordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
});

// USER PROFILE CHANGE PASSWORD
export const userChangePassword = yup.object().shape({
  oldPassword: yup
    .string()
    .required(CURRENT_PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(14, MAXIMUM_30_CHAR),
  newPassword: yup
    .string()
    .required(NEW_PASSWORD_REQUIRED)
    .matches(paswordRegex, PASSWORD_REGEX)
    .test('notEqual', 'New password must not match the current password', function (value) {
      const oldPassword = this.parent.oldPassword;
      return value !== oldPassword;
    }),
  confirmPassword: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("newPassword"), ""], CONFIRM_PASSWORD_MATCH),
});

// USER PROFILE UPDATE
export const userProfileUpdateSchema = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  lastName: yup.string().required(LAST_NAME_REQUIRED),
  langKey: yup.string().required(LANG_KEY_REQUIRED),
  phoneNo: yup.string().min(7, MINIMUM_7_DIGIT).max(15, MAXIMUM_15_DIGIT).matches(/^\d+$/, PHONE_NUMBER_VALIDATION),

})

// Agency Signup Schema
export const AgencySingupSchema = yup.object().shape({
  i_agree: yup.boolean().oneOf([true], I_AGREE_REQUIRED).required(I_AGREE_REQUIRED),
  agencyName: yup.string().required('Agency name is required'),
  contactName: yup.string().required('Contact name is required '),
  agencyAddress: yup.string().required('Agency address is required '),
  commercialRegistration: yup.string().required('Commercial registeration is required '),
  companyNationalId: yup.string().required('Company national-id is required '),
  firstName: yup.string().required('Agent first name is required '),
  lastName: yup.string().required('Agent last name is required '),
  email: yup.string().required('Agent email is required ').email(VALID_EMAIL),
  agencyEmail: yup
    .string()
    .email(VALID_EMAIL)
    .required(EMAIL_REQUIRED),
  password: yup
    .string()
    .required(EMAIL_REQUIRED)
    .matches(paswordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
});

// Agent Signup Schema
export const AgentSingupSchema = yup.object().shape({
  i_agree: yup.boolean().oneOf([true], I_AGREE_REQUIRED).required(I_AGREE_REQUIRED),
  firstName: yup.string().required('Agent first name is required '),
  lastName: yup.string().required('Agent last name is required '),
  email: yup.string().required(EMAIL_REQUIRED).email(VALID_EMAIL),
  agencyId: yup
    .string()
    .required('Agency is required'),
  password: yup
    .string()
    .required(EMAIL_REQUIRED)
    .matches(paswordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
});

// Singup
export const ContactUsFormSchema = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME_REQUIRED),
  lastName: yup.string().required(LAST_NAME_REQUIRED),
  email: yup
    .string()
    .email(VALID_EMAIL)
    .required(EMAIL_REQUIRED),
  textMessage: yup.string().required(TEXT_MESSAGE_REQUIRED).max(255, MAXIMUM_255_CHAR),
});

// ADVANCE SEARCH VALIDATION SCHEMA
export const advancedSearchValidation = yup.object().shape({

  minAreaSqm: yup.number().nullable().min(0, NO_NEGATIVE_VALUE),
  maxAreaSqm: yup.number().nullable().min(0, NO_NEGATIVE_VALUE).when('minAreaSqm', (minAreaSqm, schema) => {
    return schema.test({
      test: function (maxAreaSqm) {
        return !minAreaSqm || !maxAreaSqm || maxAreaSqm >= minAreaSqm;
      },
      message: 'Maximum value must be greater than or equal to minimum value',
    });
  }),
})

// TOUR SCHEDULE VALIDATION SCHEMA
export const tourScheduleValidationSchema = yup.object().shape({
  time: yup.string().required(TIME_REQUIRED),

  countryCode: yup.string().required(COUNTRY_CODE_REQUIRED).notOneOf(['972'],INVALID_COUNTRY_CODE),
  phoneNumber: yup.string().required(PHONE_NO_REQUIRED).min(7, MINIMUM_7_DIGIT).max(15, MAXIMUM_15_DIGIT).matches(/^\d+$/, PHONE_NUMBER_VALIDATION),
 
  message: yup.string().nullable().required(TEXT_MESSAGE_REQUIRED).max(255, MAXIMUM_255_CHAR),
  otp: yup.number().nullable().when("isOtpSend", {
    is: true,
    then: yup.number().required(OTP_REQUIRED),
    otherwise: yup.number().nullable()
  }),
  terms: yup.bool().required(I_AGREE_REQUIRED).oneOf([true], I_AGREE_REQUIRED)
});

// REPORT PROPERTY VALIDATION
export const reportPropertyValidationSchema = yup.object().shape({
  // name: yup.string().required(NAME_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  complaintReason: yup.string().required(COMPLAINT_REASON_REQUIRED),
  // countryCode: yup.string().nullable().min(2, MINIMUM_2_DIGIT).max(10, MAXIMUM_10_DIGIT),
  // phoneNumber: yup.string().nullable().min(7, MINIMUM_7_DIGIT).max(15, MAXIMUM_15_DIGIT).matches(/^\d+$/, PHONE_NUMBER_VALIDATION),
  // email: yup
  //   .string()
  //   .email(VALID_EMAIL)
  //   .required(EMAIL_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  message: yup.string().required(TEXT_MESSAGE_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
})

// PHONE NUMBER VALIDATION SCHEMA
export const phoneNoValidationSchema = yup.object().shape({
  countryCode: yup.string().required(COUNTRY_CODE_REQUIRED).notOneOf(['972'], INVALID_COUNTRY_CODE),
  phoneNumber: yup.string().required(PHONE_NO_REQUIRED).min(7, MINIMUM_7_DIGIT).max(10, MAXIMUM_10_DIGIT).matches(/^\d+$/, PHONE_NUMBER_VALIDATION),
  otp: yup.number().when("isOtpSend", {
    is: true,
    then: yup.number().required(OTP_REQUIRED),
    otherwise: yup.number().nullable()
  })
})